<template>
  <div class="MonitorOverviewMap">
    <div class="top">
      <div class="title">Locations map</div>
      <div class="description">Here you can see all the nodes which are enabled for this monitor and how they ping your site</div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="map" ref="map"></div>
      </div>
    </div>
    <monitor-tooltip ref="monitorTooltip"
                     :monitor="monitor"
                     v-show="false" />
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { bus } from '../../../../main'
import MonitorTooltip from './MapMonitorTooltip.vue'

const locationSVG = 'M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z'

export default {
  components: {
    MonitorTooltip
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      map: null,
      worldPolygonSeries: null,
      monitorImageSeries: null,
      nodeImageSeries: null,
      lineSeries: null
    }
  },

  mounted () {
    requestIdleCallback(() => {
      this.initMap()
      this.putMonitorOnMap()
      this.putNodesOnMap()
      this.initMonitorTooltip()
      this.initNodeTooltips()

      this.map.events.on('ready', () => {
        this.putConnectionsToMonitor()
      })
    })

    bus.$on('refreshMap', () => {
      setTimeout(() => {
        this.initMap()
        this.putMonitorOnMap()
        this.putNodesOnMap()
        this.initMonitorTooltip()
        this.initNodeTooltips()
        this.map.events.on('ready', () => {
          this.putConnectionsToMonitor()
        })
      }, 2000)
    })
  },

  methods: {
    initMap () {
      this.map = am4core.create(this.$refs.map, am4maps.MapChart)
      am4core.useTheme(am4themes_animated)

      this.map.chartContainer.wheelable = false
      this.map.projection = new am4maps.projections.Miller()
      this.map.geodata = am4geodata_worldLow
      this.map.seriesContainer.draggable = false
      this.map.seriesContainer.resizable = false
      this.map.maxZoomLevel = 1

      this.worldPolygonSeries = this.map.series.push(new am4maps.MapPolygonSeries())
      this.worldPolygonSeries.useGeodata = true
      this.worldPolygonSeries.fillOpacity = 1
      this.worldPolygonSeries.exclude = ['AQ']
      // this.worldPolygonSeries.calculateVisualCenter = true

      let graticuleSeries = this.map.series.push(new am4maps.GraticuleSeries())
      graticuleSeries.mapLines.template.line.strokeOpacity = 0.01

      let template = this.worldPolygonSeries.mapPolygons.template
      template.fill = am4core.color('#d4edda')
    },

    putMonitorOnMap () {
      this.monitorImageSeries = this.map.series.push(new am4maps.MapImageSeries())
      this.monitorImageSeries.data = this.monitorLocation
      this.monitorImageSeries.zIndex = 5

      let template = this.monitorImageSeries.mapImages.template

      template.propertyFields.latitude = 'latitude'
      template.propertyFields.longitude = 'longitude'

      template.nonScaling = true

      template.setStateOnChildren = true
      template.states.create('hover')

      template.horizontalCenter = 'middle'
      template.verticalCenter = 'middle'

      let interfaceColors = new am4core.InterfaceColorSet()

      // White circle
      let originHitCircle = template.createChild(am4core.Circle)
      originHitCircle.radius = 11
      originHitCircle.fill = interfaceColors.getFor('background')

      // Svg
      let originTargetIcon = template.createChild(am4core.Sprite)
      originTargetIcon.fill = '#6551E0'
      originTargetIcon.strokeWidth = 0
      originTargetIcon.scale = 1.3
      originTargetIcon.horizontalCenter = 'middle'
      originTargetIcon.verticalCenter = 'middle'
      originTargetIcon.path = locationSVG
    },

    putNodesOnMap () {
      let interfaceColors = new am4core.InterfaceColorSet()

      this.nodeImageSeries = this.map.series.push(new am4maps.MapImageSeries())
      this.nodeImageSeries.data = this.nodeLocations
      this.nodeImageSeries.zIndex = 5

      let template = this.nodeImageSeries.mapImages.template

      template.nonScaling = true
      template.tooltipHTML = `
        Location: {title} <br />
        Uptime: {uptime} <br />
        Response time: ~{average_response_time} sec.
      `

      template.fill = interfaceColors.getFor('alternativeBackground')
      template.setStateOnChildren = true
      template.states.create('hover')

      template.propertyFields.latitude = 'latitude'
      template.propertyFields.longitude = 'longitude'

      let destinationHitCircle = template.createChild(am4core.Circle)
      destinationHitCircle.radius = 7
      destinationHitCircle.fillOpacity = 1
      destinationHitCircle.fill = interfaceColors.getFor('background')

      let destinationTargetIcon = template.createChild(am4core.Sprite)
      destinationTargetIcon.scale = 0.7
      destinationTargetIcon.path = locationSVG
      destinationTargetIcon.horizontalCenter = 'middle'
      destinationTargetIcon.verticalCenter = 'middle'
    },

    putConnectionsToMonitor () {
      let lineSeries = this.map.series.push(new am4maps.MapLineSeries())
      lineSeries.mapLines.template.line.strokeWidth = 1
      lineSeries.mapLines.template.line.strokeOpacity = 1
      lineSeries.mapLines.template.line.nonScalingStroke = true
      lineSeries.mapLines.template.line.strokeDasharray = '2'
      lineSeries.zIndex = 1

      let origin = this.monitorImageSeries.dataItems.getIndex(0)

      this.nodeImageSeries.dataItems.each(item => {
        let line = lineSeries.mapLines.create()

        line.stroke = '#6551E0'
        line.imagesToConnect = [item.mapImage, origin.mapImage]

        let ping = line.lineObjects.create()
        ping.position = 0
        ping.width = 48
        ping.height = 48
        ping.mapLine = line

        let pingImage = ping.createChild(am4core.Circle)
        pingImage.radius = 3
        pingImage.strokeWidth = 0
        pingImage.fill = '#6551E0'
        pingImage.fillOpacity = 0.5

        // this.animate(ping, context.average_response_time * 10000)
      })

      lineSeries.toBack()
      this.worldPolygonSeries.toBack()
    },

    animate (ping, speed) {
      let animation = ping.animate({
        from: 0,
        to: 1,
        property: 'position'
      }, speed, am4core.ease.sinInOut)

      animation.events.on('animationended', () => {
        this.animate(ping, speed)
      })
    },

    initMonitorTooltip () {
      let tooltip = this.monitorImageSeries.tooltip
      tooltip.label.interactionsEnabled = true
      tooltip.keepTargetHover = true

      tooltip.getFillFromObject = false
      tooltip.background.filters.clear()
      tooltip.background.cornerRadius = 0
      tooltip.background.fill = am4core.color('#6551E0')
      tooltip.maxWidth = 500
      tooltip.width = 500
      tooltip.padding = 50

      let template = this.monitorImageSeries.mapImages.template
      if (this.$refs.monitorTooltip) {
        template.tooltipHTML = this.$refs.monitorTooltip.$el.innerHTML
      }
    },

    initNodeTooltips () {
      let tooltip = this.nodeImageSeries.tooltip
      tooltip.label.interactionsEnabled = true
      tooltip.keepTargetHover = true

      tooltip.getFillFromObject = false
      tooltip.background.filters.clear()
      tooltip.background.cornerRadius = 0
      tooltip.background.fill = am4core.color('#6551E0')
      tooltip.maxWidth = 500
      tooltip.width = 500
      tooltip.padding = 50
    }
  },

  computed: {
    monitorLocation () {
      return [{
        'id': 'monitor',
        'title': this.monitor.ip,
        'destinations': this.monitor.nodes.filter(function (node) {
          return node.active
        }),
        'latitude': this.monitor.latitude,
        'longitude': this.monitor.longitude,
        'scale': 1.5,
        'zoomLevel': 1,
        'zoomLongitude': -20.1341,
        'zoomLatitude': 49.1712
      }]
    },

    nodeLocations () {
      return this.monitor.nodes.filter(function (node) {
        return node.active
      }).map(node => ({
        id: node.location,
        title: node.title,
        latitude: node.latitude,
        longitude: node.longitude,
        uptime: this.$options.filters.uptime(node.uptime),
        average_response_time: node.average_response_time
      }))
    },

    ...mapState('nodes', [
      'nodes'
    ])
  },

  beforeDestroy () {
    if (this.map) {
      this.map.dispose()
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorOverviewMap {
    .top {
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }

      .description {
        color: #777;
      }
    }

    .map {
      width: 100%;
      height: 500px;
    }
  }
</style>
