<template>
  <div class="MonitorLocations">
    <template v-if="!monitor">
      <div class="loader">
        <base-loader-dual-ring />
      </div>
    </template>
    <template v-else>
      <div class="top">
        <div class="title">Monitor Locations</div>
        <no-active-nodes-alert v-if="!hasActiveNodes" />
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <monitor-nodes :monitor="monitor"
                         @toggle="onNodeToggle()" />
        </div>
      </div>

      <locations-map :monitor="monitor" />
    </template>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import LocationsMap from './LocationsMap/Map.vue'

import MonitorNodes from '@/components/Monitor/MonitorNodes/MonitorNodes.vue'
import NoActiveNodesAlert from '@/components/Monitor/MonitorNodes/MonitorNodesNoActiveNodesAlert.vue'

export default {
  components: {
    MonitorNodes,
    NoActiveNodesAlert,
    LocationsMap
  },

  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null,
      hasActiveNodes: true
    }
  },

  created () {
    this.loadMonitor()
  },

  methods: {
    async loadMonitor () {
      this.monitor = await monitorsApi.find(this.monitorId)
      this.hasActiveNodes = this.monitor.nodes.some(node => node.active)
    },

    async onNodeToggle () {
      await this.loadMonitor()
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorLocations {
    .loader {
      @include flex-center;

      width: 100%;
      height: 100%;

      margin-top: 40px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }
    }
  }
</style>
