<template>
  <div class="MonitorLocations">
    <monitor-locations :monitor-id="monitorId" />
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import MonitorLocations from '@/components/Monitor/Locations/Locations.vue'

export default {
  components: {
    MonitorLocations
  },

  metaInfo: {
    title: 'Monitor Locations'
  },

  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null,
      hasActiveNodes: true
    }
  },

  created () {
    this.loadMonitor()
  },

  methods: {
    async loadMonitor () {
      this.monitor = await monitorsApi.find(this.monitorId)
      this.hasActiveNodes = this.monitor.nodes.some(node => node.active)
    },

    async onNodeToggle () {
      await this.loadMonitor()
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorLocations {
    .loader {
      @include flex-center;

      width: 100%;
      height: 100%;

      margin-top: 40px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5;
        color: #444;
      }
    }
  }
</style>
