<template>
  <div class="tooltip">
    {{ monitor.ip }} <br />
  </div>
</template>

<script>
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  }
}
</script>
